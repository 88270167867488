<template>
  <div style="width: 100%">
    <!-- PC端样式 -->
    <div v-if="isMobile == false" style="background: #fff">
      <div class="footerContPC" style="">
        <div style="width: 30%">
          <p class="footerTit">关于快小象</p>
          <!-- <p class="footerInfo">{{basicInfoList.web_name}}是由北京欧信软件服务有限公司独立开发的全网智能运力调度平台，是国内创新的智能聚合配送服务平台。采用大数据+AI智能，为全国美饿等自配送商户、线上平台、个人，提供高效、便捷、省钱、贴心的同城配送服务。聚焦共享经济，以聚合服务为依托，专注于专人直送，同城跑腿，专车快送，顺路配送等，为用户提供7×24小时，平均1分钟响应、10分钟上门、同城1小时送达的聚合速递服务。{{basicInfoList.web_name}}，一切从您开始！</p> -->
          <p class="footerInfo" style="text-indent: 2em">
            快小象是国内独立“智能聚合”平台，专注解决自配送商户、线上平台、个人同城配送需求的，为所有客户提供高效、全面的同城配送及跑腿服务。始终坚持以客户为中心，通过5年下沉积累了大量科技研发成果，快速、高效、训练有素的团队快速获得市场认可，在全国范围内拥有众多合作伙伴。
          </p>
        </div>
        <!-- <div style="width:30%"> -->
        <div>
          <p class="footerTit">联系我们</p>
          <p class="footerInfo">地址：北京市海淀区安宁庄西路9号院2号楼1层01</p>
          <p class="footerInfo">
            联系电话：<a
              style="color: #5c5c5c"
              :href="'tel:' + basicInfoList.tel"
              >{{ basicInfoList.tel }}</a
            >
          </p>
          <a
            class="footerInfo"
            style="display: inline-block"
            :href="'mailto:' + basicInfoList.email"
            >邮件：maxiaohui@kuaixiaoxiang.com</a
          >
        </div>
        <div>
          <p class="footerTit" style="text-align: left">关注我们</p>
          <img
            class="footerInfo"
            style="width: 60%"
            src="../assets/img/20220509114720.png"
            alt=""
            srcset=""
          />
        </div>
      </div>
      <div style="width: 90%; margin-left: 5%">
        <a-divider style="background: #b2b2b2"></a-divider>
      </div>
      <div
        style="
          color: #040303;
          text-align: center;
          padding-bottom: 20px;
          position: relative;
        "
      >
        <div style="font-size: 13px; color: #000">
          {{ basicInfoList.copyright }} <a href="https://beian.miit.gov.cn" target="_blank">{{ basicInfoList.record }}</a>
        </div>
        <div></div>
      </div>
    </div>
    <!-- 移动端样式 -->
    <a-row v-else :span="24" class="footerCont">
      <a-col :span="24" style="padding-top: 20px">
        <p class="footerTit">关于快小象</p>
        <!-- <p class="footerInfo">{{basicInfoList.web_name}}是由北京欧信软件服务有限公司独立开发的全网智能运力调度平台，是国内创新的智能聚合配送服务平台。采用大数据+AI智能，为全国美饿等自配送商户、线上平台、个人，提供高效、便捷、省钱、贴心的同城配送服务。聚焦共享经济，以聚合服务为依托，专注于专人直送，同城跑腿，专车快送，顺路配送等，为用户提供7×24小时，平均1分钟响应、10分钟上门、同城1小时送达的聚合速递服务。{{basicInfoList.web_name}}，一切从您开始！</p> -->
        <p class="footerInfo" style="text-indent: 2em">
          快小象是国内独立“智能聚合”平台，专注解决自配送商户、线上平台、个人同城配送需求的，为所有客户提供高效、全面的同城配送及跑腿服务。始终坚持以客户为中心，通过5年下沉积累了大量科技研发成果，快速、高效、训练有素的团队快速获得市场认可，在全国范围内拥有众多合作伙伴。
        </p>
      </a-col>
      <a-col :span="24" style="margin-top: 20px">
        <p class="footerTit">联系我们</p>
        <p class="footerInfo">地址：北京市海淀区安宁庄西路9号院2号楼1层01</p>
        <p class="footerInfo">
          联系电话：<a
            style="color: #5c5c5c"
            :href="'tel:' + basicInfoList.tel"
            >{{ basicInfoList.tel }}</a
          >
        </p>
        <a
          class="footerInfo"
          style="display: inline-block"
          :href="'mailto:' + basicInfoList.email"
          ><span v-html="space"></span>邮件：maxiaohui@kuaixiaoxiang.com</a
        >
      </a-col>
      <a-col :span="24" style="margin-top: 20px">
        <p class="footerTit" style="text-align: center">关注我们</p>
        <!-- <img  src="../assets/img/20220509114720.png" alt=""> -->
        <img
          class="footerInfo"
          style="width: 60%"
          src="../assets/img/20220509114720.png"
          alt=""
          srcset=""
        />
      </a-col>
    </a-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      basicInfoList: [],
      AndroidQR: "",
      gzh: "../assets/img/20220509114720.png",
      isMobile: false,
      space: "&nbsp;",
    };
  },
  mounted() {
    this.getBasicInfo();
    // this.getQRcode()
    if (this._isMobile()) {
      this.isMobile = true;
      // this.$router.replace('/m_index');
    } else {
      this.isMobile = false;
      // this.$router.replace('/pc_index');
    }
  },
  methods: {
    getBasicInfo() {
      this.$axios
        .post(this.$api.server_api.v1.FOOTER_BASIC_INFO)
        .then((res) => {
          this.basicInfoList = res.data.data;
        });
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    // getQRcode(){
    //   this.$axios.post(
    //     this.$api.server_api.v1.QRCODE_LIST
    //   ).then( res=> {
    //     // this.QRcdoeList = res.data.data
    //     for( let i in res.data.data){
    //       if( res.data.data[i].platform == 4){
    //         this.gzh = res.data.data[i].thumb
    //       }
    //       // else if(res.data.data[i].platform == 2){
    //       //   this.IOSQR =  res.data.data[i].thumb
    //       // }else if(res.data.data[i].platform == 3){
    //       //   this.miniProgram = res.data.data[i].thumb
    //       // }
    //     }
    //   })
    // }
  },
};
</script>
<style scoped>
.footerCont {
  background: #ffff;
  text-align: center;
  padding-bottom: 20px;
}
.footerTit {
  font-size: 20px;
  font-weight: bold;
  color: #606060;
}
.footerInfo {
  font-size: 15px;
  margin-top: 20px;
  align-items: flex-end;
  color: #5c5c5c;
  line-height: 30px;
}
.footerContPC {
  background: #fff;
  z-index: 99;
  position: relative;
  width: 100%;
  color: #000;
  display: flex;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 30px;
  justify-content: space-between;
}
</style>
